<template>
  <div>
    <!-- <loader2 v-if="load == true"></loader2> -->
    <section class="breadcrumb-outer text-center bg-orange">
      <div class="container">
        <h2 class="display-none"></h2>
      </div>
    </section>
    <div id="mt-banner1" class="innerbanner">
      <div class="container-fluid">
        <div
          class="featured-image"
          :style="{
            'background-image': `url(${
              'https://backend.scocenter.uz/storage/' + posts2.image
            })`,
          }"
        ></div>
        <div class="banner-caption">
          <div class="banner_caption_text">
            <h1>{{ posts2[`title_${$i18n.locale}`] }}</h1>
            <div class="item-meta">
              <span style="font-size: 22px; color: #333a65">
                {{ created_at }}
              </span>
              <span style="font-size: 22px; color: #333a65; float: right">
                <i class="fa fa-eye"></i> {{ posts2.views_count }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Banner -->
    <!--* Blog Main Sec*-->
    <section id="blog_main_sec" class="section-inner">
      <div class="container">
        <!--*Blog Content Sec*-->
        <div class="blog-detail-main">
          <div v-html="posts2[`body_${$i18n.locale}`]" class="post_body"></div>
          <div class="follow_us">
            <ul class="social_icons">
              <li>
                <a href="https://www.facebook.com/Scocenteruz/" target="_blank">
                  <i class="fa fa-facebook"></i
                ></a>
              </li>
              <li>
                <a href="https://twitter.com/admin_scocenter" target="_blank"
                  ><i class="fa fa-twitter"></i
                ></a>
              </li>
              <li>
                <a href="https://www.instagram.com/scocentreuz/" target="_blank"
                  ><i class="fa fa-instagram"></i
                ></a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/channel/UC60Sr1891-7qCIkjD1_12dA"
                  target="_blank"
                  ><i class="fa fa-youtube"></i
                ></a>
              </li>
              <li>
                <a href="https://t.me/scocenteruz"
                  ><i class="fa fa-paper-plane-o" target="_blank"></i
                ></a>
              </li>
            </ul>
          </div>
          <!-- <div class="author_box">
	<div class="author_img">
<img src="images/blog/author.png" alt="Author">
 </div>
<div class="author_bio">
<h5>Jhon Snow</h5>
<p>Duis a enim vel mauris ultrices. Nullam aliquet velit ac velit tempus in semper an neque auctor. Aenean ligula mi, auctor sed tempus.Duis a enim vel mauris ultrices. Nullam aliquet velit ac velit tempus in semper an neque auctor.
 Aenean ligula mi, auctor sed tempus.</p>
<ul>
<li>
<a href="#">
    <i class="fa fa-facebook"></i>
 </a>
</li>
<li>
<a href="#">
<i class="fa fa-twitter"></i>
</a>
</li>
<li>
<a href="#">
<i class="fa fa-google-plus"></i>
 </a>
</li>
<li>
 <a href="#">
 <i class="fa fa-linkedin"></i>
</a>
</li>
 </ul>
 </div>
    </div> -->
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import api from "../apis";
export default {
  name: "Post",
  data() {
    return {
      posts2: "",
      load: true,
      created_at: "",
    };
  },
  methods: {
    async makeRequest() {
      try {
        const res = await api
          .get("/post/" + this.$route.params.id)
          .then((response) => {
            console.log(response);
            this.posts2 = response.data.details;
            this.created_at = this.posts2.created_at.slice(0, 10);
            // this.metaInfo.title = this.posts2.title_uz
            console.log(this.title);
            console.log(this.posts2);
            setTimeout(() => (this.load = false), 500);
          });
      } catch (error) {
        console.error(error);
      }
    },
  },
  mounted() {
    return this.makeRequest();
  },
};
</script>
<style scoped>
#mt-banner1.innerbanner {
  height: 480px !important;
}
.featured-image {
  position: absolute;
  z-index: -3;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  width: calc(50% - 50px);
  border-radius: 0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-left: 40px;
  border-radius: 20px;
}
.display-none {
  display: none;
}
#mt-banner1 {
  position: relative;
  padding: 0;
  height: 452px !important;
  margin-top: 0px;
}
#mt-banner1.innerbanner .banner_caption_text {
  margin: 5% 0 0 46%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  box-shadow: 0 0 25px #cccccc40;
  border-radius: 0 26px;
  background: #fff;
  padding: 40px;
  left: inherit;
}
@media (min-width: 1500px) and (max-width: 1600px) {
  #mt-banner1.innerbanner .banner_caption_text {
    width: 46%;
  }
}
@media (max-width: 500px) {
  #mt-banner1.innerbanner .banner_caption_text h1 {
    font-size: 16px;
  }
}
@media (min-width: 500px) and (max-width: 991px) {
  #mt-banner1.innerbanner .banner_caption_text h1 {
    font-size: 19px;
  }
}
@media (min-width: 300px) and (max-width: 991px) {
  #mt-banner1.innerbanner .banner_caption_text {
    width: 100%;
    margin: 0;
    margin-top: -99px;
  }
  .featured-image {
    z-index: -3;
    top: 150px;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    border-radius: 0;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin: 0;
    border-radius: 20px;
  }
  .display-none {
    display: block;
  }
  .blog-detail-main {
    width: 100%;
    background: #fff;
    border-radius: 26px;
    margin: 0px auto 0 !important;
    z-index: 2;
    position: relative;
  }
}
.bg-orange {
  margin: 0;
  background-color: white !important;
  background-image: none;
}
section {
  padding: 54px 0 !important;
  position: relative;
}
.breadcrumb-outer h2 {
  padding: 70px 0 15px !important;
}
h1 {
  font-size: 25px;
}
.d-flex {
  display: flex;
  flex-direction: column;
}
.blog-detail-main {
  width: 100%;
  background: #fff;
  border-radius: 26px;
  z-index: 2;
  position: relative;
}
.section-inner {
  padding: 50px 0;
  position: relative;
}
.blog-detail-main .post_body p {
  font-size: 18px;
  line-height: 1.7;
  margin-bottom: 25px;
}
blockquote {
  font-size: 14px;
  font-weight: 300;
  background-color: #faf8f8;
  margin-bottom: 15px;
  border-left: 4px solid #00418c;
  padding: 30px 70px 30px 70px;
  line-height: 24px;
  color: #444444;
  margin-bottom: 20px;
  font-style: italic;
  position: relative;
}
</style>